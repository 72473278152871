import { getElementsValue } from "~/mixins/alchemyVue/utilities"

const DEFAULT_IMAGE =
  "https://d1zkbwtxrqnx8s.cloudfront.net/2021/12/07/13/28/49/7580f2ce-d134-4378-a5ec-0dbec8f68dba/cs_og.png"
const DEFAULT_CARD_TYPE = "summary"
const DEFAULT_OG_TYPE = "website"

function socialTagsElement(page) {
  return page.fixedElements?.find((el) => el.name === "social_tags")
}

function piOgImage(page) {
  if (!socialTagsElement(page)) {
    return DEFAULT_IMAGE
  }
  return getElementsValue(socialTagsElement(page), "image") || DEFAULT_IMAGE
}

function piTwitterCardType(page) {
  if (!socialTagsElement(page)) {
    return DEFAULT_CARD_TYPE
  }
  return getElementsValue(socialTagsElement(page), "card") || DEFAULT_CARD_TYPE
}

function piOgType(page) {
  if (!socialTagsElement(page)) {
    return DEFAULT_OG_TYPE
  }
  return getElementsValue(socialTagsElement(page), "type") || DEFAULT_OG_TYPE
}

export default function usePageInfo(page) {
  const config = useRuntimeConfig()
  if (page) {
    const fullUrl = `${config.public.BASE_URL}${page.urlPath}`
    return {
      title: page.title,
      link: [
        {
          rel: "canonical",
          href: fullUrl,
        },
      ],
      meta: [
        {
          name: "keywords",
          content: page.metaKeywords,
        },
        {
          property: "og:title",
          content: page.title,
        },
        {
          property: "og:type",
          content: piOgType(page),
        },
        {
          property: "og:url",
          content: fullUrl,
        },
        {
          property: "og:image:width",
          content: "1200",
        },
        {
          property: "og:image:height",
          content: "630",
        },
        {
          property: "og:image",
          content: piOgImage(page),
        },
        {
          property: "twitter:image",
          content: piOgImage(page),
        },
        {
          property: "twitter:url",
          content: fullUrl,
        },
        {
          property: "twitter:card",
          content: piTwitterCardType(page),
        },
        {
          name: "description",
          content: page.metaDescription,
        },
        {
          property: "og:description",
          content: page.metaDescription,
        },
        {
          property: "twitter:description",
          content: page.metaDescription,
        },
      ],
    }
  } else {
    return {}
  }
}
